<template>
  <div class="table_wrapper">
    <v-card class="p-3">
      <v-card-title>Language ( {{ form.lang }} ) Edit </v-card-title>
      <v-divider></v-divider>

      <v-dialog v-model="dialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Add New Translation</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Group*"
                      :rules="rules"
                      v-model="form.group"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Key*"
                      :rules="rules"
                      v-model="form.key"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Translation Text*"
                      :rules="rulestext"
                      v-model="form.text"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              :disabled="!valid"
              text
              @click="saveTranslation"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card class="p-3" flat>
        <v-row>
          <v-col cols="12">
            <v-toolbar dark color="indigo darken-1">
              <v-col cols="4">
                <v-autocomplete
                  v-model="form.lang"
                  solo-inverted
                  :items="languages"
                  label="Please Select Lang"
                  item-text="name"
                  clearable
                  :loading="loading"
                  item-value="id"
                  flat
                  hide-no-data
                  hide-details
                  v-on:change="getTranslation"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  v-model="form.group"
                  solo-inverted
                  :items="groups"
                  label="Please Select Group"
                  item-text="group"
                  clearable
                  :loading="loading"
                  item-value="group"
                  flat
                  hide-no-data
                  hide-details
                  v-on:change="getTranslation"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-btn
                  elevation="0"
                  color="indigo darken-2"
                  block
                  v-on:click="(dialog = true), (isedit = false)"
                >
                  <v-icon>mdi-plus</v-icon>
                  Add New
                </v-btn>
              </v-col>
            </v-toolbar>
          </v-col>
          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                Language Items
              </v-card-title>

              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        ID
                      </th>
                      <th class="text-left">
                        Group
                      </th>
                      <th class="text-left">
                        Key
                      </th>
                      <th class="text-left">
                        Translation
                      </th>
                      <th class="text-left">
                        Edit
                      </th>
                      <th class="text-left">
                        Group Delete
                      </th>
                      <th class="text-left">
                        Key Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="data">
                    <tr v-for="(item, index) in data" :key="index">
                      <td>
                        {{ item.id }}
                      </td>
                      <td>
                        {{ item.group }}
                      </td>
                      <td>
                        {{ item.key }}
                      </td>
                      <td>
                        {{ item.text }}
                      </td>
                      <td>
                        <v-btn
                          small
                          elevation="0"
                          dark
                          fab
                          color="indigo"
                          v-on:click="
                            (form = item), (dialog = true), (isedit = true)
                          "
                        >
                          <v-icon small>mdi-pencil</v-icon>
                        </v-btn>
                      </td>
                      <td class="text-center">
                        <v-btn
                          small
                          elevation="0"
                          dark
                          fab
                          color="purple darken-5"
                          v-on:click="deleteItem('group')"
                        >
                          <v-icon small>mdi-close</v-icon>
                        </v-btn>
                      </td>

                      <td class="text-center">
                        <v-btn
                          small
                          elevation="0"
                          dark
                          fab
                          color="deep-orange darken-1"
                          v-on:click="deleteItem('key')"
                        >
                          <v-icon small>mdi-close</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card>

      <v-bottom-sheet v-model="loading" persistent>
        <v-sheet class="text-center" height="200px">
          <div class="pt-5">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
            <h5 class="mt-3">Loading, Please wait..</h5>
          </div>
        </v-sheet>
      </v-bottom-sheet>
    </v-card>

    <confirm ref="confirm"></confirm>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import RulesClass from "../../services/rules";
import Confirm from "../Components/Confirm.vue";

export default {
  name: "LanguagesEdit",
  data() {
    return {
      isedit: false,
      valid: false,
      dialog: false,
      dialog2: false,
      loading: true,
      data: [],
      groups: [],
      languages: [],
      token: localStorage.getItem("authToken"),
      form: {
        group: "",
        key: "",
        text: "",
        lang: "",
      },
      requireds: {
        allowSpaces: false,
        min: 2,
      },
      requiredstext: {
        allowSpaces: true,
        min: 2,
      },
    };
  },
  components: {
    confirm: Confirm,
  },
  methods: {
    deleteItem: function(type) {
      if (type == "group") {
        this.$refs.confirm
          .open(
            "Delete",
            "Are you sure you want to delete this group from all languages?",
            { color: "red" }
          )
          .then((confirm) => {
            if (confirm) {
              this.$refs.confirm
                .open(
                  "Please confirm again.",
                  "If you click to YES, this group will be deleted from all languages!",
                  { color: "red" }
                )
                .then((confirm) => {
                  if (confirm) {
                  }
                });
            }
          });
      }
    },
    saveTranslation: function() {
      this.dialog = false;
      this.loading = true;

      if (this.isedit == true) {
        axios
          .put(
            process.env.VUE_APP_API_URL +
              "language/" +
              this.form.lang +
              "/translations/" +
              this.form.id +
              "?api_token=" +
              this.token,
            this.form
          )
          .then((res) => {
            this.loading = false;
            this.form.text = "";
            this.getTranslation();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.form.id = null;
        axios
          .post(
            process.env.VUE_APP_API_URL +
              "language/" +
              this.form.lang +
              "/translations?api_token=" +
              this.token,
            this.form
          )
          .then((res) => {
            this.loading = false;
            this.form.text = "";
            this.getTranslation();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    getTranslation: function() {
      this.loading = true;
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "language/" +
            this.form.lang +
            "/translations?page=1&per_page=500&group=" +
            this.form.group +
            "&api_token=" +
            this.token
        )
        .then((res) => {
          this.data = res.data.data.reverse();
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getData: function() {
      this.form.lang = this.$route.params.id;
      const url =
        process.env.VUE_APP_API_URL + "language/groups?api_token=" + this.token;
      axios
        .get(url)
        .then((res) => {
          this.groups = res.data.data;
          this.loading = false;
        })
        .catch((err) => console.log(err));
    },
    getList() {
      const url =
        process.env.VUE_APP_API_URL +
        "language/filter?page=" +
        this.page +
        "&per_page=" +
        this.per_page +
        "&api_token=" +
        this.token;
      axios
        .post(url)
        .then((res) => {
          this.languages = res.data.data.data;
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    this.getData();
    this.getList();
    this.form.lang = this.$route.params.id;
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    rules() {
      let rulesClass = new RulesClass();
      return rulesClass.req(this.requireds);
    },
    rulestext() {
      let rulesClass = new RulesClass();
      return rulesClass.req(this.requiredstext);
    },
  },
};
</script>
